@import './variables.sass';
@import './mixins.sass';

.btn {
	border: none;
	outline: none;
	background: transparent;
	color: inherit;
	font: inherit;
	text-decoration: none;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: $btn-default-padding;
	font-size: $btn-default-font-size;
	line-height: 1.4;
	text-align: center;
	font-weight: bold;
	border-radius: $btn-default-border-radius;
	transition: all .2s ease-out;

	&-hide {
		display: none; }

	&-icon {
		transition: .2s color ease-out; }

	&-default {
		background-color: $btn-default-bg-color;
		color: $blackColorDark;
		&:hover,
		&:focus {
			&:not([disabled]) {
				background-color: darken($btn-default-bg-color,10%); } } }
	&-payment {
		&[disabled] {
			background: rgba($greyColor, .4) !important;
			border: 2px solid rgba($whiteColor, 0); } }
	&-accented {
		background-color: $btn-accented-color;
		color: $whiteColor;
		&:hover,
		&:focus {
			&:not([disabled]) {
				background-color: $btn-accented-hover-color;
				box-shadow: 0 8px 16px rgba($blackColorDark, 0.1); } } }
	&-thin-accented {
		border: 2px solid $btn-accented-color;
		color: $blackColorDark;
		&:hover,
		&:focus {
			&:not([disabled]) {
				background-color: $btn-accented-color;
				color: $whiteColor; } } }
	&-fb {
		border: 2px solid $facebookColor;
		color: $facebookColor;
		&:hover:not([disabled]) {
			box-shadow: 0 8px 16px rgba($blackColorDark, 0.1); } }
	&-google {
		border: 2px solid $googleColor;
		color: $googleColor;
		&:hover:not([disabled]) {
			box-shadow: 0 8px 16px rgba($blackColorDark, 0.1); } }
	&-tab {
		width: 50%;
		height: 96px;
		background-color: $mainColor;
		color: $whiteColor;
		padding: 0;
		border-radius: 0;
		&_active {
			background-color: $greyColorLight2;
			color: $blackColor; } }
	&-thin-primary {
		border: 2px solid $mainColor;
		color: $mainColor;
		&:hover:not([disabled]) {
			background-color: $mainColor;
			color: $whiteColor;
			.btn-icon,
			.btn-reset {
				color: $whiteColor; } }
		&_color-black {
			color: $blackColorDark; }
		&-trash {
			color: $redColor; }
		.btn-icon {
			color: $mainColor; } }
	&-reset {
		@include buttonReset();
		color: $mainColor;
		&__icon {
			&-light {
				color: $mainColor; } } }
	&-link {
		border-bottom: 1px solid;
		&_active {
			border-bottom-color: transparent; }
		&_small {
			font-size: $btn-small-font-size;
			color: $mainColorDark; }
		&_green {
			font-size: $btn-small-font-size;
			color: $mainColor;
			text-decoration: none;
			&:hover {
				color: $mainColorDark; } } }
	&-link-decoration {
		cursor: pointer;
		border-bottom: 1px solid rgba($blackColor, 0.5) !important;
		color: rgba($blackColor, 0.5);
		font-size: $font-size-sm; }
	&:hover:not([disabled]) {
		cursor: pointer; }
	&[disabled]:not([class*=" icon-"]) {
		opacity: 0.5;
		// background: $btn-disabled-bg-color
 }		// color: $whiteColor
	&[disabled] {
		cursor: not-allowed;
 }		// color: $btn-disabled-bg-color
	&-w100 {
		width: 100%; }
	&_small {
		padding: $btn-small-padding;
		border-radius: $btn-small-border-radius;
		font-size: $btn-small-font-size;
		border-width: 1px; }
	&_bordered {
		border: 1px solid rgba(2, 3, 3, .25);
		&:hover:not([disabled]) {
			border-color: $btn-accented-color;
			color: $btn-accented-color; } }
	[class^="icon-"], [class*=" icon-"] {
		margin-right: 5px; }
	&-reset {
		background-color: transparent;
		height: auto;
		padding: 0;
		margin: 0;
		border: 0;
		border-radius: 0; }
	&-beige {
		background: $greyColor;
		color: $mainColor;
		&:hover {
			background: $mainColor;
			color: $whiteColor; } } }

iframe { // for turn off errors
	display: none; }
