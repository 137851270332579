@import './variables.sass';

.gt-select {
	.gt-select {
		&__value {
			&-container {
				padding: 0;
				font-size: $form-font-size; } }
		&__control {
			border-width: 0 0 2px;
			border-style: solid;
			border-color: $mainColor !important;
			border-radius: 0;
			background: none;
			min-height: 35px;
			&--is-focused {
				box-shadow: none; }
			&--menu-is-open {
				.gt-select__indicator {
					transform: rotate(180deg); } } }
		&__input {
			input {
				height: auto; } }
		&__indicator {
			padding: 8px;
			color: $mainColor;
			&:hover {
				color: $mainColorDark; }
			&-separator {
				display: none; } }
		&__menu {
			z-index: 100;
			box-shadow: none;
			border-radius: 0;
			margin: 0;
			border-width: 1px;
			border-style: solid;
			border-color: transparent rgba($blackColor,.1) rgba($blackColor,.1);
			&-list {
				padding: 0; } }
		&__option {
			padding: 13px 10px;
			font-size: $font-size-xs;
			transition: .2s background-color ease-in-out;
			color: $blackColor !important;
			&--is-focused {
				background-color: rgba($mainColor,.1); }
			&--is-selected {
				background-color: $mainColor; } } }
	&.gt-select_accented {
		.gt-select {
			&__control {
				border-color: $form-accent-border-color !important; }
			&__indicator {
				color: $form-accent-border-color;
				&:hover {
					color: $beigeColorDark; } }
			&__single-value {
				color: $mainColorDark; }
			&__option {
				&--is-focused {
					background-color: rgba($form-accent-border-color,.1); }
				&--is-selected {
					background-color: $form-accent-border-color; } } } }
	&.gt-select_accented {
		&_accented-color {
			.gt-select {
				&__single-value {
					color: $beigeColorDark; } } } } }
