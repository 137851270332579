/* Main Colors */
/* Reports Colors */
/* additional Colors */
/* graph */
/* fonts */
/* Main Colors */
/* Reports Colors */
/* additional Colors */
/* graph */
/* fonts */
html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*, *:before, *:after {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background-color: #fff; }

.trn-disabled {
  transition: none !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul {
  margin: 0;
  padding: 0; }

input {
  font: inherit;
  color: inherit;
  padding: 0; }

#root {
  display: flex;
  flex-direction: column;
  position: relative; }

.public-main {
  z-index: 2;
  display: flex;
  flex-grow: 1;
  padding-bottom: 65px;
  padding-top: 30px; }

.content {
  transition: max-width .2s ease-out;
  padding-bottom: 65px; }
  .content-wrapper {
    height: 100%;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .content-wrapper {
        display: flex; } }
    @media (max-width: 767px) {
      .content-wrapper {
        padding-top: 80px; } }
    @media (min-width: 768px) {
      .content-wrapper .menu-collapsed ~ .content {
        max-width: calc(100% - 65px); } }
    @media (min-width: 1200px) {
      .content-wrapper .menu-expanded ~ .content {
        max-width: calc(100% - 320px); } }
  .content {
    flex-grow: 1;
    flex-shrink: 1; }

.container {
  margin: 0 auto; }
  @media (min-width: 1000px) {
    .container {
      padding: 0 30px; } }
  @media (max-width: 999px) {
    .container {
      padding: 0 20px; } }

/* Main classes for fonts from style guide */
.m-h1 {
  font-size: 36px;
  font-weight: bold;
  color: #000; }

.m-h2 {
  font-size: 30px;
  font-weight: 300;
  color: #000; }

.m21 {
  font-size: 21px;
  font-weight: 500;
  color: #000; }

.r18 {
  font-size: 17px; }

.r16 {
  font-size: 16px; }

.r12 {
  font-size: 15px; }

.l16 {
  font-size: 15px;
  font-weight: 300; }

.l14 {
  font-size: 13px; }

.public-auth__wrapper {
  border-radius: 4px;
  overflow: hidden; }

.public-auth__body_forgot {
  background: linear-gradient(180deg, #FF8300 0%, #0F0A21 100%);
  box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }

.public-forgot__title {
  font-size: 21px;
  color: #fff; }

.public-forgot__instruction {
  font-size: 13px;
  color: #fff; }

.public-confirmation__text {
  color: #fff; }

.create-new-password {
  background: linear-gradient(180deg, #B2CE55 0%, #9BBC2B 100%);
  box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }

.react-datepicker__input-container input {
  height: 35px;
  border: 0;
  border-bottom: 2px solid #ECC48D;
  background-color: inherit; }

.react-datepicker-popper {
  z-index: 12 !important; }

.react-datepicker__day--selected {
  background-color: #ECC48D !important; }

.react-datepicker__day--keyboard-selected {
  background-color: #ECC48D !important; }

.tabs {
  display: flex;
  justify-content: center; }
  .tabs button {
    background: transparent;
    padding: 0;
    border: none;
    outline: none; }
    .tabs button:not([disabled]) {
      cursor: pointer; }
  .tabs__button {
    font-weight: 500;
    color: #0F0A21;
    text-decoration: none;
    height: 50px; }
    @media (min-width: 1000px) {
      .tabs__button {
        margin: 0 30px;
        font-size: 21px; } }
    @media (min-width: 768px) and (max-width: 999px) {
      .tabs__button {
        margin: 0 20px;
        font-size: 17px; } }
    @media (max-width: 767px) {
      .tabs__button {
        margin: 0 15px;
        font-size: 16px; } }
    .tabs__button.active {
      position: relative; }
      .tabs__button.active:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -5px;
        right: -5px;
        height: 3px;
        background: #FF8300; }

.table-actions button + button {
  margin-left: 15px; }

.table-actions button.icon-trash {
  color: #F05656; }

/* Main Colors */
/* Reports Colors */
/* additional Colors */
/* graph */
/* fonts */
.btn {
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 11px 30px 12px;
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
  font-weight: bold;
  border-radius: 30px;
  transition: all .2s ease-out; }
  .btn-hide {
    display: none; }
  .btn-icon {
    transition: .2s color ease-out; }
  .btn-default {
    background-color: #F4F4F4;
    color: #020303; }
    .btn-default:hover:not([disabled]), .btn-default:focus:not([disabled]) {
      background-color: #dbdbdb; }
  .btn-payment[disabled] {
    background: rgba(244, 244, 244, 0.4) !important;
    border: 2px solid rgba(255, 255, 255, 0); }
  .btn-accented {
    background-color: #FF8300;
    color: #fff; }
    .btn-accented:hover:not([disabled]), .btn-accented:focus:not([disabled]) {
      background-color: #0F0A21;
      box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }
  .btn-thin-accented {
    border: 2px solid #FF8300;
    color: #020303; }
    .btn-thin-accented:hover:not([disabled]), .btn-thin-accented:focus:not([disabled]) {
      background-color: #FF8300;
      color: #fff; }
  .btn-fb {
    border: 2px solid #3B5999;
    color: #3B5999; }
    .btn-fb:hover:not([disabled]) {
      box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }
  .btn-google {
    border: 2px solid #DB4437;
    color: #DB4437; }
    .btn-google:hover:not([disabled]) {
      box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }
  .btn-tab {
    width: 50%;
    height: 96px;
    background-color: #FF8300;
    color: #fff;
    padding: 0;
    border-radius: 0; }
    .btn-tab_active {
      background-color: #f9f9f9;
      color: #000; }
  .btn-thin-primary {
    border: 2px solid #FF8300;
    color: #FF8300; }
    .btn-thin-primary:hover:not([disabled]) {
      background-color: #FF8300;
      color: #fff; }
      .btn-thin-primary:hover:not([disabled]) .btn-icon,
      .btn-thin-primary:hover:not([disabled]) .btn-reset {
        color: #fff; }
    .btn-thin-primary_color-black {
      color: #020303; }
    .btn-thin-primary-trash {
      color: #F05656; }
    .btn-thin-primary .btn-icon {
      color: #FF8300; }
  .btn-reset {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    color: #FF8300; }
    .btn-reset:not([disabled]) {
      cursor: pointer; }
    .btn-reset__icon-light {
      color: #FF8300; }
  .btn-link {
    border-bottom: 1px solid; }
    .btn-link_active {
      border-bottom-color: transparent; }
    .btn-link_small {
      font-size: 13px;
      color: #0F0A21; }
    .btn-link_green {
      font-size: 13px;
      color: #FF8300;
      text-decoration: none; }
      .btn-link_green:hover {
        color: #0F0A21; }
  .btn-link-decoration {
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px; }
  .btn:hover:not([disabled]) {
    cursor: pointer; }
  .btn[disabled]:not([class*=" icon-"]) {
    opacity: 0.5; }
  .btn[disabled] {
    cursor: not-allowed; }
  .btn-w100 {
    width: 100%; }
  .btn_small {
    padding: 5px 14px;
    border-radius: 20px;
    font-size: 13px;
    border-width: 1px; }
  .btn_bordered {
    border: 1px solid rgba(2, 3, 3, 0.25); }
    .btn_bordered:hover:not([disabled]) {
      border-color: #FF8300;
      color: #FF8300; }
  .btn [class^="icon-"], .btn [class*=" icon-"] {
    margin-right: 5px; }
  .btn-reset {
    background-color: transparent;
    height: auto;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0; }
  .btn-beige {
    background: #F4F4F4;
    color: #FF8300; }
    .btn-beige:hover {
      background: #FF8300;
      color: #fff; }

iframe {
  display: none; }

/* Main Colors */
/* Reports Colors */
/* additional Colors */
/* graph */
/* fonts */
input[type=checkbox],
input[type=radio] {
  display: block;
  position: absolute;
  top: -8px;
  z-index: 10;
  opacity: 0;
  height: 16px;
  width: 16px;
  margin: 0;
  cursor: pointer; }
  input[type=checkbox]:not([disabled]) ~ span,
  input[type=radio]:not([disabled]) ~ span {
    cursor: pointer; }

.static-form-label {
  font-size: 11px; }
  .static-form-label-grey {
    color: #818181; }

.form-control-default {
  position: relative; }
  .form-control-default input {
    position: relative;
    z-index: 11;
    width: 100%;
    background-color: transparent;
    height: 35px;
    font-size: 16px;
    color: #0F0A21;
    outline: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    border-bottom: 2px solid #FF8300;
    transition: border-bottom .4s; }
    .form-control-default input ~ span {
      position: absolute;
      font-size: 16px;
      top: 50%;
      left: 0;
      margin-top: -10px;
      transition: all .4s; }
    .form-control-default input.brown-border {
      color: #0F0A21; }
      .form-control-default input.brown-border ~ span {
        color: #0F0A21;
        z-index: 10; }
      .form-control-default input.brown-border:focus ~ span,
      .form-control-default input.brown-border:valid ~ span,
      .form-control-default input.brown-border[readOnly] ~ span {
        top: -3px;
        font-size: 11px; }
    .form-control-default input.grey-border {
      color: #020303; }
      .form-control-default input.grey-border[disabled] {
        border-bottom: 2px solid rgba(244, 244, 244, 0.4); }
      .form-control-default input.grey-border ~ span {
        color: #020303; }
      .form-control-default input.grey-border:focus ~ span,
      .form-control-default input.grey-border:valid ~ span {
        top: -3px;
        font-size: 11px;
        color: #818181; }
    .form-control-default input.error-border {
      border-bottom: 2px solid #F05656; }
      .form-control-default input.error-border ~ span {
        color: #F05656 !important; }
    .form-control-default input::placeholder {
      color: #000; }
    .form-control-default input:focus ~ span,
    .form-control-default input:valid ~ span,
    .form-control-default input[readOnly] ~ span {
      top: -3px;
      font-size: 11px; }
  .form-control-default input[readOnly] {
    opacity: 0.5;
    cursor: default; }
  .form-control-default__checkbox {
    position: relative;
    display: flex;
    min-height: 1px; }
    .form-control-default__checkbox.opacity {
      opacity: 0; }
    .form-control-default__checkbox input:checked + span:before {
      background-color: #FF8300; }
    .form-control-default__checkbox input:checked + span:after {
      opacity: 1;
      transform: rotate(0); }
    .form-control-default__checkbox span {
      display: flex;
      position: absolute;
      top: -8px;
      padding-left: 26px;
      height: auto;
      font-size: 13px;
      line-height: 1.3;
      text-align: left;
      color: #020303;
      cursor: pointer; }
      .form-control-default__checkbox span:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        margin: auto;
        border: 2px solid #FF8300;
        border-radius: 2px;
        transition: background-color .2s ease-out; }
      .form-control-default__checkbox span:after {
        content: '\E915';
        font-family: 'icons';
        color: #fff;
        position: absolute;
        top: 1px;
        left: 2px;
        font-size: 12px;
        opacity: 0;
        transform: rotate(-45deg);
        transition: all .2s ease-out; }
  .form-control-default__radio {
    position: relative; }
    .form-control-default__radio input:checked + span:after {
      opacity: 1;
      transform: scale(1); }
    .form-control-default__radio input[disabled] + span {
      opacity: .5; }
    .form-control-default__radio span {
      display: flex;
      position: relative;
      padding-left: 24px;
      color: #020303;
      font-size: 16px;
      text-align: left; }
      .form-control-default__radio span:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 2px solid #FF8300;
        border-radius: 50%; }
      .form-control-default__radio span:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #FF8300;
        border-radius: 50%;
        opacity: 0;
        transform: scale(3.4);
        transition: all .2s ease-out; }
    .form-control-default__radio.brown-border span:before {
      border: 2px solid #FF8300; }

.form-control-error {
  position: absolute;
  font-size: 11px;
  text-align: left;
  color: #F05656; }

.birth-date__form-input__birthday input[readOnly] {
  color: #C4C4C4; }

@font-face {
  font-family: 'icons';
  src: url(./static/f/icons/icons.ttf) format("truetype"), url(./static/f/icons/icons.woff) format("woff"), url(./static/f/icons/icons.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-tablet:before {
  content: "\e928"; }

.icon-shape:before {
  content: "\e927"; }

.icon-eye:before {
  content: "\e900"; }

.icon-eye-close:before {
  content: "\e901"; }

.icon-login:before {
  content: "\e902"; }

.icon-logout:before {
  content: "\e903"; }

.icon-home:before {
  content: "\e904"; }

.icon-settings:before {
  content: "\e905"; }

.icon-settings:before {
  content: "\e905"; }

.icon-magic-stick:before {
  content: "\e906"; }

.icon-structure:before {
  content: "\e907"; }

.icon-user:before {
  content: "\e908"; }

.icon-group:before {
  content: "\e909"; }

.icon-piechart:before {
  content: "\e90a"; }

.icon-key:before {
  content: "\e90b"; }

.icon-info:before {
  content: "\e90c"; }

.icon-question:before {
  content: "\e90d"; }

.icon-chat:before {
  content: "\e90e"; }

.icon-arrow-small-left:before {
  content: "\e90f"; }

.icon-arrow-small-right:before {
  content: "\e910"; }

.icon-arrow-small-bottom:before {
  content: "\e913"; }

.icon-arrow-small-top:before {
  content: "\e914"; }

.icon-photo:before {
  content: "\e911"; }

.icon-key-full:before {
  content: "\e912"; }

.icon-ok:before {
  content: "\e915"; }

.icon-cross:before {
  content: "\e916"; }

.icon-trash:before {
  content: "\e917"; }

.icon-edit:before {
  content: "\e918"; }

.icon-download:before {
  content: "\e919"; }

.icon-download-cloud:before {
  content: "\e91a"; }

.icon-download-cloud-2:before {
  content: "\e91b"; }

.icon-envelope:before {
  content: "\e91c"; }

.icon-list:before {
  content: "\e91d"; }

.icon-schema:before {
  content: "\e91e"; }

.icon-folders:before {
  content: "\e91f"; }

.icon-copy:before {
  content: "\e920"; }

.icon-loading-circles:before {
  content: "\e921"; }

.icon-user-2:before {
  content: "\e922"; }

.icon-gamepad:before {
  content: "\e923"; }

.icon-share:before {
  content: "\e924"; }

.icon-restore:before {
  content: "\e925"; }

.icon-flag:before {
  content: "\e926"; }

p {
  margin: 0 0 20px; }

/* Main Colors */
/* Reports Colors */
/* additional Colors */
/* graph */
/* fonts */
.gt-select .gt-select__value-container {
  padding: 0;
  font-size: 16px; }

.gt-select .gt-select__control {
  border-width: 0 0 2px;
  border-style: solid;
  border-color: #FF8300 !important;
  border-radius: 0;
  background: none;
  min-height: 35px; }
  .gt-select .gt-select__control--is-focused {
    box-shadow: none; }
  .gt-select .gt-select__control--menu-is-open .gt-select__indicator {
    transform: rotate(180deg); }

.gt-select .gt-select__input input {
  height: auto; }

.gt-select .gt-select__indicator {
  padding: 8px;
  color: #FF8300; }
  .gt-select .gt-select__indicator:hover {
    color: #0F0A21; }
  .gt-select .gt-select__indicator-separator {
    display: none; }

.gt-select .gt-select__menu {
  z-index: 100;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1); }
  .gt-select .gt-select__menu-list {
    padding: 0; }

.gt-select .gt-select__option {
  padding: 13px 10px;
  font-size: 13px;
  transition: .2s background-color ease-in-out;
  color: #000 !important; }
  .gt-select .gt-select__option--is-focused {
    background-color: rgba(255, 131, 0, 0.1); }
  .gt-select .gt-select__option--is-selected {
    background-color: #FF8300; }

.gt-select.gt-select_accented .gt-select__control {
  border-color: #FF8300 !important; }

.gt-select.gt-select_accented .gt-select__indicator {
  color: #FF8300; }
  .gt-select.gt-select_accented .gt-select__indicator:hover {
    color: #E3C59A; }

.gt-select.gt-select_accented .gt-select__single-value {
  color: #0F0A21; }

.gt-select.gt-select_accented .gt-select__option--is-focused {
  background-color: rgba(255, 131, 0, 0.1); }

.gt-select.gt-select_accented .gt-select__option--is-selected {
  background-color: #FF8300; }

.gt-select.gt-select_accented_accented-color .gt-select__single-value {
  color: #E3C59A; }
