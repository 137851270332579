@import '../../styles/_variables.sass';

// .general-modal__body_modal-success
// 	background: $gradient-default

.modal-success {
	@media #{$tablet_desktop} {
		width: 420px;
		padding: 93px 32px 40px; }
	@media #{$phone} {
		padding: 35px 20px; }
	img {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		@media #{$tablet_desktop} {
			top: -72px;
			width: 164px; }
		@media #{$phone} {
			top: -31px;
			width: 62px; } }
	&__text {
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: $font-size-sm;
		text-align: center; } }
