@import '../../styles/_variables.sass';

.success-info {
	&__success {
		width: 420px;
		min-height: 300px;
		padding: 110px 30px 20px;
		color: $whiteColor;
		text-align: center;
		background: linear-gradient(180deg, $mainColor 0%, $mainColorDark 100%);
		img {
			position: absolute;
			top: -72px;
			left: 0;
			right: 0;
			margin: auto; }
		&-text {
			font-size: $font-size-h3;
			font-weight: 500;
			margin-bottom: 30px; }
		&-button {
			margin-bottom: 20px; } } }
