/* Main Colors */

$mainColor: #FF8300;
$mainColorDark: #0F0A21;
$whiteColor: #ffffff;

$beigeColor: #ECC48D;
$beigeColorDark: #E3C59A;
$beigeColorLight: #F9EDDC;
$beigeColorGrey: #818181;

$blackColorDark: #020303;

$greyColorDark: #595D62;
$greyColor: #F4F4F4;
$greyColorLight: #E5E5E5;
$greyColorLight2: #f9f9f9;

$redColor: #F05656;

/* Reports Colors */

$brownColor: #A5855E;
$purpleColor: #896BAF;
$greenColor: #559610;
$blueColor: #0033A0;
$facebookColor: #3B5999;
$googleColor: #DB4437;

$polygon77: #f44336;
$polygon16: #e91e63;
$polygon8: #CD5C5C;
$d: #CD6689;
$polygon11: #E36DE0;
$polygon77: #FF81E1;
$polygon3: #ED7D8F;
$polygon77: #FF7E91;
$polygon0: #F7A35C;
$polygon12: #ff9800;
$polygon17: #ffc107;
$polygon77: #FFD972;
$polygon77: #ffeb3b;
$polygon4: #E1E36D;
$polygon1: #90ED7D;
$polygon13: #82D344;
$polygon7: #66CDAA;
$polygon15: #64ffda;
$polygon77: #1de9b6;
$polygon77: #00C0A9;
$polygon10: #18ffff;
$polygon77: #00DCDD;
$polygon77: #00bcd4;
$polygon77: #6DE1E3;
$polygon2: #7CB5EC;
$polygon5: #8085E9;
$polygon19: #2196f3;
$polygon9: #6F6DE3;
$polygon77: #3f51b5;
$polygon14: #673ab7;
$d: #A460F4;
$polygon6: #9932CC;
$polygon18: #9c27b0;
/* additional Colors */

$whiteColor: #fff;
$blackColor: #000;
$palette1: #C4C4C4;

/* graph */

$_omega: #F05656;
$_psi: #ff0000;
$_gamma: #ff9900;
$_beta: #3d85c6;
$_alpha: #559610;

/* fonts */

$fontDefault: 'Exo 2', sans-serif;

$gutter-main: 30px;
$gutter-mobile: 20px;

$header-height: 65px;
$header-account-height: 65px;

$btn-default-bg-color: $greyColor;
$btn-accented-color: $mainColor;
$btn-accented-hover-color: $mainColorDark;
$btn-primary-color: $beigeColor;
$btn-disabled-bg-color: $greyColorDark;
$btn-default-height: 48px;
$btn-default-padding: 11px 30px 12px;
$btn-default-border-radius: 30px;
$btn-default-font-size: 17px;
$btn-small-height: 32px;
$btn-small-padding: 5px 14px;
$btn-small-border-radius: 20px;
$btn-small-font-size: 13px;

$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 21px;
$font-size-main: 15px;
$font-size-lg: 17px;
$font-size-md: 16px;
$font-size-sm: 15px;
$font-size-xs: 13px;
$font-size-xxs: 11px;

$form-border-color: $blackColor;
$form-default-border-color: $mainColor;
$form-accent-border-color: $mainColor;
$form-default-readonly-color: $palette1;
$form-brown-readonly-color: $brownColor;
$form-disabled-bg-color: $greyColorDark;
$form-font-size: 16px;
$form-label-size: 11px;

$border-radius-panel: 4px;
$border-radius-base: 4px;
$border-radius-md: 25px;

$menuCollapsedWidth: 65px;
$menuExpandedWidth: 320px;

$gradient-default: linear-gradient(180deg, $mainColor 0%, $mainColorDark 100%);

//$xlgWidth: 1600px
//$lgWidth: 1400px
$mdWidth: 1200px;
$smWidth: 1000px;
$tabletWidth: 768px;

$md: "(min-width: #{$mdWidth})";
$sm: "(min-width: #{$smWidth}) and (max-width: #{$mdWidth - 1})";
$desktop: "(min-width: #{$smWidth})";
$tablet: "(min-width: #{$tabletWidth}) and (max-width: #{$smWidth - 1})";
$phone: "(max-width: #{$tabletWidth - 1})";

$md_lg: "(min-width: #{$mdWidth})";
$sm_md: "(min-width: #{$smWidth}) and (max-width: #{$mdWidth - 1})";
$tablet_sm: "(min-width: #{$tabletWidth}) and (max-width: #{$mdWidth - 1})";
$tablet_desktop: "(min-width: #{$tabletWidth})";
$phone_sm: "(max-width: #{$mdWidth - 1})";
$phone_tablet: "(max-width: #{$smWidth - 1})";
